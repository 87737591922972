import type { RegistrationType } from '@kanbu/schema';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<RegistrationType>();

export function useRegistrationsCols() {
  const { t } = useTranslation(['glossary']);

  return useMemo(
    () => [
      columnHelper.accessor(row => row.email, {
        id: 'email',
        header: t('glossary:labels.email'),
        meta: {
          sortable: 'email',
        },
      }),
      columnHelper.accessor(row => row.firstName, {
        id: 'firstName',
        header: t('glossary:labels.firstName'),
        meta: {
          sortable: 'firstName',
        },
      }),
      columnHelper.accessor(row => row.membershipName, {
        id: 'membershipName',
        header: t('glossary:labels.membershipName'),
        meta: {
          sortable: 'membershipName',
        },
      }),
      columnHelper.accessor(row => row.clubId, {
        id: 'clubId',
        header: t('glossary:labels.clubId'),
        meta: {
          sortable: 'clubId',
        },
      }),
      columnHelper.accessor(row => row.createdAt, {
        id: 'createdAt',
        header: t('glossary:labels.createdAt'),
        cell: row => formatUtils.date(row.getValue(), DateFormat.DateTime),
        meta: {
          sortable: 'createdAt',
        },
      }),
    ],
    [t],
  );
}
