import { loginSchema, type LoginDTO } from '@kanbu/schema/contracts';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { Button, toast } from '@utima/ui';
import type * as Form from '@utima/ui-informed';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicForm } from '@/components/form/BasicForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { trpc, trpcClient } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

export const Route = createFileRoute('/_authLayout/login/')({
  component: LoginPage,
  validateSearch: (search: Record<string, unknown>): { redirect?: string } => {
    return {
      redirect: search.redirect as string,
    };
  },
});

const initialValues: LoginDTO = {
  email: '',
  password: '',
};

export function LoginPage() {
  const { t } = useTranslation(['glossary', 'auth']);
  const navigate = useNavigate();
  const { setJwt, setUser, logout } = useBoundStore(state => ({
    setJwt: state.setJwt,
    setUser: state.setUser,
    logout: state.logout,
  }));

  const loginMutation = trpc.auth.login.useMutation();
  const search = Route.useSearch();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (formState: Form.TypedFormState<LoginDTO>) => {
    setIsLoading(true);

    return loginMutation.mutate(formState.values, {
      onError: (error: unknown) => {
        logout();

        console.log(error);
        toast.error(t('auth:toasts.login.error.title'), {
          description: t('auth:toasts.login.error.description'),
        });
      },
      onSuccess: async data => {
        setJwt(data.access);

        // Fetch user me
        const user = await trpcClient.users.me.query();
        setUser(user);

        // Redirect to the page where the user was trying to glossary
        if (search.redirect) {
          navigate({ to: search.redirect, replace: true });
        } else {
          navigate({ to: '/' });
        }
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  const { schema } = useFieldsSchema(
    [
      {
        name: 'email',
        label: t('glossary:labels.email'),
        required: true,
        uiProps: {
          type: 'email',
        },
      },
      {
        name: 'password',
        label: t('glossary:labels.password'),
        required: true,
        uiProps: {
          type: 'password',
          passwordPreview: true,
        },
      },
    ],
    [t],
  );

  return (
    <BasicForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      zodSchema={loginSchema}
      className='flex w-full flex-col gap-6'
    >
      <SchemaFields schema={schema} />

      <Button
        variant='primary'
        size='lg'
        type='submit'
        disabled={isLoading}
        loading={isLoading}
      >
        {t('glossary:actions.login')}
      </Button>

      <div className='text-center'>
        <Link
          to='/forgotten-password'
          className='text-sm hover:text-primary hover:underline'
        >
          {t('auth:actions.toForgottenPassword')}
        </Link>
      </div>
    </BasicForm>
  );
}
