import type { DocumentType } from '@kanbu/schema';
import type { EvaluationTestCase } from '@kanbu/schema/contracts';
import { Dialog, Button, Tooltip } from '@utima/ui';
import { CopyCheckIcon, Copy } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trpc } from '@/services/trpc';

interface GenerateTestCasesDialogProps {
  document: DocumentType | null;
}

export function GenerateTestCasesDialog({
  document,
}: GenerateTestCasesDialogProps) {
  const { t } = useTranslation(['glossary']);
  const [isCopied, setIsCopied] = useState(false);
  const [testCases, setTestCases] = useState<EvaluationTestCase[]>([]);
  const generateTestCasesMutation = trpc.evaluation.generate.useMutation({
    onSuccess: (data: any) => {
      setTestCases(data.testCases);
    },
  });

  const handleGenerateTestCases = () => {
    if (document) {
      generateTestCasesMutation.mutate({
        documentId: document.id,
      });
    }
  };

  const copyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(JSON.stringify(testCases, null, 2))
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      });
  }, [testCases]);

  return (
    <Dialog.Content className='flex max-h-[95%] w-full max-w-[1200px] flex-col'>
      <Dialog.Title>
        {t('glossary:dialogs.generateTestCases.title')}
      </Dialog.Title>
      <Dialog.Description>
        {t('glossary:dialogs.generateTestCases.description')}
      </Dialog.Description>
      {testCases.length > 0 && (
        <div className='relative size-full overflow-auto'>
          <div className='fixed bottom-24 right-12'>
            <Tooltip title={t('glossary:actions.copy')} delayDuration={0}>
              <Button
                onClick={copyToClipboard}
                variant='primary'
                size='icon-xs'
              >
                {isCopied ? (
                  <CopyCheckIcon className='size-4' />
                ) : (
                  <Copy className='size-4' />
                )}
              </Button>
            </Tooltip>
          </div>
          <pre className='overflow-auto whitespace-pre-wrap font-mono text-xs'>
            {JSON.stringify(testCases, null, 2)}
          </pre>
        </div>
      )}
      <Dialog.Footer>
        <Button
          onClick={handleGenerateTestCases}
          disabled={generateTestCasesMutation.isPending || !document}
          loading={generateTestCasesMutation.isPending}
        >
          {t('glossary:actions.generateTestCases')}
        </Button>
        <Dialog.Close asChild>
          <Button variant='ghost' outline>
            {t('glossary:actions.close')}
          </Button>
        </Dialog.Close>
      </Dialog.Footer>
    </Dialog.Content>
  );
}
