import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { useRegistrationsCols } from '@/features/registrations/useRegistrationsCols';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc.ts';

export const Route = createFileRoute('/_baseLayout/registrations/')({
  component: RegistrationsPage,
});

export function RegistrationsPage() {
  const { t } = useTranslation(['globals', 'registrations']);
  const columns = useRegistrationsCols();
  const handleQuery = useTableQuery(trpcClient.registrations.findAll.query);

  return (
    <TablePage
      title={t('registrations:texts.manage')}
      breadcrumbs={[
        { label: t('globals:routeNames.registrations'), to: '/registrations' },
      ]}
      columns={columns}
      queryKey={getQueryKey(trpc.registrations.findAll)}
      onQueryFn={handleQuery}
    />
  );
}
